import styled, { css } from 'styled-components';
import { media, sizes } from 'styles';

export const VisibleTabs = styled.div`
  height: 66px;
  overflow: hidden;

  ul {
    display: flex;
    height: 64px;
    justify-content: space-between;
    margin: 0;
    overflow-y: hidden;
    padding: 0;
    white-space: nowrap;
    width: 100%;

    @media screen and (max-width: ${sizes.phone}px) {
      & {
        overflow-x: scroll;
      }
    }

    li {
      border-bottom: 2px solid ${({ theme }) => (theme.main.colors.lightestGrey)};
      color: ${({ theme }) => (theme.main.colors.darkGrey)};
      cursor: pointer;
      display: inline-block;
      padding: 16px 25px;
      text-align: center;

      @media (max-width: ${sizes.phone}px) {
        & {
          min-width: 200px;
        }
      }

      ${media.phone`
        min-width: 300px;
      `}
    }

    li[data-active="true"] {
      border-bottom: 2px solid ${({ theme }) => (theme.main.colors.green)};
      font-weight: 700;
    }

    ${media.tablet`
      justify-content: center;
    `}

    ${media.desktop`
      align-items: center;
      justify-content: center;
    `}
  }
`;

export const VisibleTabsContent = styled.div`
  position: relative;
`;

export const TabContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: 0;
  opacity: 0;
  padding: 25px 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .4s linear;
  visibility: hidden;

  ${props => props.isActive && css`
    opacity: 1;
    position: relative;
    transition: opacity .4s linear;
    visibility: visible;
  `}
`;