import React from 'react';
import { string } from 'prop-types';
import * as S from './styled';

const LinkButton = ({
  type,
  label,
  url,
  size,
  target,
}) => (
  <S.StyledLinkButton
    type={type}
    href={url}
    target={target}
    size={size}
    title={label}
    rel="noopener noreferrer"
  >
    <span>{label}</span>
  </S.StyledLinkButton>
);

LinkButton.propTypes = {
  type: string.isRequired,
  label: string.isRequired,
  url: string.isRequired,
  target: string.isRequired,
  size: string,
};

LinkButton.defaultProps = {
  size: '',
};

export default LinkButton;