import React, { useContext } from 'react';
import uuid from 'uuid';
import { Container } from 'styles';
import Tabs from 'components/Tabs';
import LinkButton from 'components/LinkButton';
import * as S from 'styles/Home/styled';
import * as Context from 'context';

const renderLinkButtonList = (list) => {
  return list.map(({ label, link }) => (
    <LinkButton
      key={uuid.v4()}
      type="rounded"
      label={label}
      url={link}
      target="_blank"
    />
  ))
};

const CategoryList = ({ children }) => {
  return (
    <div>{children}</div>
  );
};

const Careers = () => {
  const { careersContext } = Context;
  const careersList = useContext(careersContext);

  const contents = [
    { content: <CategoryList>{renderLinkButtonList(careersList.links.categories)}</CategoryList> },
    { content: <CategoryList>{renderLinkButtonList(careersList.links.cities)}</CategoryList> },
    { content: <CategoryList>{renderLinkButtonList(careersList.links.salaries)}</CategoryList> },
  ];

  return (
    <S.StyledSection>
      <Container>
        <S.SectionTitle>Carreiras de tecnologia em destaque</S.SectionTitle>
        <Tabs list={careersList.types} content={contents}  />
      </Container>
    </S.StyledSection>
  )
};

export default Careers;