import React, { Fragment } from 'react';
import { array } from 'prop-types';
import useTabs from 'hooks/useTabs';
import uuid from 'uuid';
import * as S from './styled';

const Tabs = ({ list, content }) => {
  const [activeTab, setActiveTab] = useTabs(0);

  return (
    <Fragment>
      <S.VisibleTabs>
        <ul>
          {list.map(({ label }, index) => (
            <li
              key={uuid.v4()}
              onClick={() => setActiveTab(index)}
              data-active={activeTab === index}>
              <span>{label}</span>
            </li>
          ))}
        </ul>
      </S.VisibleTabs>
      <S.VisibleTabsContent>
        {content.map(({ content }, index) => (
          <S.TabContent
            isActive={activeTab === index}
            key={uuid.v4()}>
            {content}
          </S.TabContent>
        ))}
      </S.VisibleTabsContent>
    </Fragment>
  );
};

Tabs.propTypes = {
  list: array.isRequired,
  content: array.isRequired,
};

export default Tabs;